import { tns } from "tiny-slider/src/tiny-slider.js"
import 'tiny-slider/src/tiny-slider.scss'

// previous owl carousel options for partners slides

// carouselPartners = ->
// $('.owl-carousel-partners').map ->
//   $(this).owlCarousel
//     navigation: false
//     slideSpeed: 600
//     paginationSpeed: 600
//     singleItem: false
//     pagination: false
//     autoHeight: true
//     autoPlay: 1500
//     stopOnHover: true
//     afterInit: (slide) ->
//   owl = $(this).data('owlCarousel')
//   return

// data-plugin-options='{"items": 6, "singleItem": false, "pagination": false, "autoPlay": true}'

document.addEventListener('DOMContentLoaded', () => {

  const slider = document.querySelector('.js-slider')
  if (slider) {
    tns({
      container: slider,
      controls: false,
      speed: 400,
      items: 4,
      nav: false,
      autoplay: true,
      autoplayButton: false,
      autoplayButtonOutput: false,  
      // autoplayHoverPause: true
    })
  }

})